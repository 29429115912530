import React, { ReactElement, useState } from 'react';
import dompurify from 'dompurify';
import sanitizerConfig from '../sanitizer-config';

import UploadFormat from './upload-format.component';
import { UploadConfig, UploadFormInput } from './upload.model';
import UploadFormFileInput from './upload-form-file-input.component';
import UploadFormInputs from './upload-form-inputs.component';
import UploadFormTos from './upload-form-tos';
import StorageService from './storage';

interface UploadFormProps {
    config: UploadConfig;
    input: UploadFormInput;
    isEmbed: boolean;
    formCompleted: boolean;
    updateInput: (input: Partial<UploadFormInput>) => void;
    updateFile: (file: File) => void;
    onSuccess: () => void;
    storageService: StorageService;
}

const mediaRequestedAcceptValues = {
    any: 'video/*,image/*',
    image: 'image/*',
    video: 'video/*'
};


const UploadForm = ({
    config, input, isEmbed, updateInput, updateFile, formCompleted, onSuccess, storageService
}: UploadFormProps):ReactElement => {
    const sanitizer = dompurify.sanitize;
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ uploadProgress, setUploadProgress ] = useState(0);

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (!loading) {
            doUpload();
        }
    }

    async function doUpload() {
        setLoading(true);
        setError(false);

        const { file, termsAcknowledged, ...customFieldInput } = input;
        const { slug } = config;
        const metaData = convertCustomFieldInputToMetaData(customFieldInput);

        try {
            await storageService.upload({
                slug,
                file,
                metaData,
                termsAcknowledged,
                progressCallback: progress => setUploadProgress(progress)
            });

            setLoading(false);
            onSuccess();
        } catch (e) {
            setLoading(false);
            setError(true);
            setUploadProgress(0);
        }
    }

    function convertCustomFieldInputToMetaData(customFieldInput: Partial<UploadFormInput>): {[key: string]: string } {
        // Ensures values are strings as required by AWS Storage. The only non string value here will be boolean, which we just convert to
        // a string
        return Object.entries(customFieldInput).reduce(
            (metaData, [ key, value ]) => {
                metaData[key] = value.toString();

                return metaData;
            },
            {}
        );
    }


    return (<>
        { !input.file ?
            <h1 className="text-4xl lg:text-5xl font-bold leading-tight mb-12">{config.title || 'Share Your Story!'}</h1>
            :
            (
                <>
                    <h1 className="text-4xl lg:text-5xl font-bold leading-tight mb-2">Almost Done!</h1>
                    <p className="mb-10 text-xl">
                        { (formCompleted || !config.customFields?.length) ?
                            'Click Upload to finish.' :
                            'Tell us a little bit more about yourself to complete the upload.'
                        }
                    </p>
                </>
            )
        }

        { error &&
        <div className="mb-4 flex items-center bg-white leading-tight text-red-600 rounded-lg p-2 shadow text-sm">
            <span className="inline-flex bg-red-600 text-white rounded-lg h-6 px-3 justify-center items-center">Error</span>
            <span className="inline-flex px-3">Sorry! There was a problem uploading your media. Please try again.</span>
        </div>
        }

        <div className="flex flex-col lg:flex-row lg:divide-x lg:divide-gray-400">
            <div className="lg:w-1/3 lg:py-4 lg:pr-8">

                {/* EXPIRED - SHOW EXPIRED STATE */}
                { config.expired &&
                <div className="border-2 border-dotted rounded-lg px-6 py-4 border-gray-400" id="contentExpired">
                    <h2 className="text-2xl font-bold mb-3">Sorry, you missed out!</h2>
                    <div className="text-xl">
                        Submissions are closed for this campaign. Check back with us again for additional campaigns like this to share
                        your story!
                    </div>
                </div>
                }


                {/* NOT EXPIRED - SHOW FULL FORM */}
                { !config.expired &&
                <form onSubmit={handleSubmit} className="relative z-10">
                    <UploadFormFileInput onChange={updateFile} accept={mediaRequestedAcceptValues[config.mediaRequested]}/>

                    {input.file &&
                    <div className="mt-12">
                        { !!config.customFields?.length &&
                            <UploadFormInputs onChange={updateInput} input={input} config={config}/>
                        }

                        { !loading &&
                        <UploadFormTos required={config.termsOfServiceRequired}
                            disabledOnEmbed={config.termsOfServiceDisabledForEmbed}
                            isEmbed={isEmbed}
                            accepted={input.termsAcknowledged}
                            tosUrl={config.termsOfServiceUrl}
                            onChange={updateInput}
                        />
                        }

                        { loading &&
                        <div className="mt-4 mb-2 text-xs">
                            Please wait while your upload finishes.
                        </div>
                        }

                        <div>
                            <button disabled={loading} className="upload-btn w-full" id="buttonUpload">
                                Upload
                                { loading && <div className="spinner spinner-2 inline-flex ml-3 transform scale-125"/> }
                            </button>
                        </div>

                        { loading && <>
                            <div className="mt-3">
                                <div className="shadow w-full bg-gray-300 h-1 overflow-hidden rounded">
                                    <div className="h-1 text-center w-1/2 transition-all duration-300 ease-in-out" style={{
                                        backgroundColor: 'var(--upload-cta-background-color)',
                                        width: `${uploadProgress}%`
                                    }}/>
                                </div>
                            </div>
                        </>}
                    </div>
                    }
                </form>
                }
            </div>

            <div className="lg:w-2/3 pt-8 lg:pl-8 lg:py-4 text-xl">
                <div className="break-words upload-content">
                    { config.description &&
                        <div dangerouslySetInnerHTML={{__html: sanitizer(config.description, sanitizerConfig)}}/>
                    }
                    { !config.description && 'Record a short video with your story!' }
                </div>
                <UploadFormat config={config}/>
            </div>
        </div>
    </>);
};

export default UploadForm;
