import React, { ReactElement } from 'react';
import dompurify from 'dompurify';
import sanitizerConfig from '../sanitizer-config';

interface UploadDoneProps {
    thankYouMessage: string;
    reset: () => void;
}

const UploadDone = ({ thankYouMessage, reset }: UploadDoneProps): ReactElement => {
    const sanitizer = dompurify.sanitize;

    return (<>
        <h1 className="text-4xl lg:text-5xl font-bold leading-tight mb-2">Thank You!</h1>
        <div className="mb-10 text-xl break-words upload-content">
            { thankYouMessage &&
            <div dangerouslySetInnerHTML={{ __html: sanitizer(thankYouMessage,sanitizerConfig ) }} />
            }
            { !thankYouMessage && 'Thanks for sharing your story!' }
        </div>
        <button onClick={reset} className="upload-btn block w-full mx-auto lg:max-w-sm" id="buttonStartOver">
            Upload More
        </button>
    </>);
};

export default UploadDone;
